import { InMemoryWebStorage, Log as OidcLog, UserProfile as Profile, User, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';
import { config } from '../config';
import { configureFetchDisplayMessages } from './lang/services';
import { UserProfile, userProfileObtained, userSessionExpired, userSessionRenewed } from './login/loginSlice';
import { mapUserProfile } from './login/userProfile';
import { store } from './setup/store';
import { accessToken } from './tokenHandling/accessToken';
import { AccessToken, accessTokenStored, idTokenStored } from './tokenHandling/tokenSlice';

interface SessionRenewedResult {
    accessToken: AccessToken;
    idToken: Profile;
    profile: UserProfile;
    locale: string;
}

// enable logging for oidc-client
if (localStorage.getItem('oidcDebug') === 'enabled') {
    OidcLog.setLogger(console);
    OidcLog.setLevel(OidcLog.DEBUG);
}

const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
    accessToken: result.access_token,
    idToken: result.profile,
    locale: result.profile?.locale ?? 'en-GB',
    profile: mapUserProfile(result.profile),
});

export const oauthConfig = {
    onSessionExpired: () => {
        accessToken.discardAccessToken();
        store.dispatch(userSessionExpired());
    },
    onSessionRenewed: (user: User) => {
        const renewResult = adaptPublishedInfo(user);
        accessToken.saveAccessToken(renewResult.accessToken);
        store.dispatch(accessTokenStored(renewResult.accessToken));
        store.dispatch(idTokenStored(renewResult.idToken));
        store.dispatch(userProfileObtained(renewResult.profile));

        store.dispatch(userSessionRenewed());

        const fetchDisplayMessages = configureFetchDisplayMessages(store);
        // You will need to get the user language yourself then
        // you may fetch the suitable messages. Depending
        // on when and from where you fetch the user settings you might
        // want to employ a loading spinner while the request is ongoing.
        fetchDisplayMessages(renewResult.locale);
    },
};

const onSigninCallback = (user: User | void): void => {
    if (user) {
        oauthConfig.onSessionRenewed(user);
    }
    // Remove the code and state parameters from the url when you are redirected from the authorize page.
    // ToDo: restore path, query and fragment after redirect
    if (window.location.pathname === '/redirect') {
        window.history.replaceState({}, document.title, '/');
    } else {
        window.history.replaceState({}, document.title, window.location.pathname);
    }
};

export const oidcConfig: AuthProviderProps = {
    authority: config.login.authority,
    client_id: config.login.clientId,
    redirect_uri: config.login.redirectUri,
    monitorSession: false,
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    onSigninCallback: onSigninCallback,
    scope: 'openid profile email api://b47264dc-06d5-4a7f-b7a0-10c48d5bd0ad/vcs',
    // ...
};
