import { FormattedMessage } from 'react-intl';
import { NavLink, Link } from 'react-router-dom';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import IframeResizer from 'iframe-resizer-react';
import useClipboard from '@rio-cloud/rio-uikit/useClipboard';
import { DEFAULT_ROUTE, ROUTE_TEMPLATES, ROUTE_CONFLOGIC } from '../../routes/Router';
import { detectFeatureToggles } from '../../configuration/setup/featureToggles';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { accessToken } from '../../configuration/tokenHandling/accessToken';

const AppHeader = () => {
    const clipboard = useClipboard();
    const navItems = [
        {
            key: 'intro',
            route: (
                <NavLink to={DEFAULT_ROUTE}>
                    <FormattedMessage id={'intl-msg:starterTemplate.sublink.intro'} />
                </NavLink>
            ),
        },
        {
            key: 'conflogic',
            route: (
                <NavLink to={ROUTE_CONFLOGIC}>
                    <FormattedMessage id={'intl-msg:starterTemplate.sublink.conflogic'} />
                </NavLink>
            ),
        },
        {
            key: 'templates',
            route: (
                <NavLink to={ROUTE_TEMPLATES}>
                    <FormattedMessage id={'intl-msg:starterTemplate.sublink.templates'} />
                </NavLink>
            ),
        },
    ].filter(Boolean);

    const actionBarItems = [
        <ActionBarItem id='userMenu' key={'userMenu'}>
            <ActionBarItem.Icon>
                <span className='icon rioglyph rioglyph rioglyph-cards-list' />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover useOffscreen>
                <h6>Actions</h6>
                <div>
                    <button
                        type='button'
                        className='btn btn-link'
                        onClick={() => clipboard.copy(`Bearer ${accessToken.getAccessToken()}`)}
                    >
                        <span className='rioglyph rioglyph-duplicate' aria-hidden='true' />
                        <span className='text-primary' aria-hidden='true'>
                            <FormattedMessage id='intl-msg:copyAccessToken' />
                        </span>
                    </button>
                </div>
                <div className='border-style-solid border-width-1 border-color-lighter border-top-only margin-left-10 margin-top-20 padding-top-10' />
                <h6>User</h6>
                <div className='padding-left-10 '>
                    <IframeResizer
                        id={'popover-iframe'}
                        src={'https://admin-eu.rio.cloud/#user-menu'}
                        checkOrigin={false}
                        style={{
                            width: '1px',
                            minWidth: '100%',
                            height: '91px',
                            border: '0',
                        }}
                    />
                </div>
            </ActionBarItem.Popover>
        </ActionBarItem>,
    ];
    return (
        <ApplicationHeader
            label={<FormattedMessage id='intl-msg:starterTemplate.moduleName' />}
            appNavigator={undefined}
            homeRoute={undefined}
            navItems={navItems}
            actionBarItems={actionBarItems}
        />
    );
};

export default AppHeader;
