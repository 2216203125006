import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { main } from './configuration';
import { store } from './configuration/setup/store';
import { ErrorBoundary } from './components/ErrorBoundary';
import { router } from './routes/Router';
import { AuthProvider, useAuth, hasAuthParams } from 'react-oidc-context';
import React from 'react';
import { oidcConfig } from './configuration/oidcConfig';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

const renderApplication = () => {
    // enableMocking().then(() => {
    createRoot(document.getElementById('root') as HTMLElement).render(
        <ErrorBoundary>
            <AuthProvider {...oidcConfig}>
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>
                <AutoSignIn />
            </AuthProvider>
        </ErrorBoundary>
    );
    // });
};

const AutoSignIn = () => {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

    // automatically sign-in
    React.useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    if (auth.isLoading) {
        // return <div>Signing you in/out...</div>;
        return <Spinner isFullSized />;
    }

    if (!auth.isLoading && !auth.isAuthenticated) {
        return <div>Unable to log in</div>;
    }
};

const enableMocking = async () => {
    const mocking = import.meta.env.VITE_ID;
    if (mocking.includes('production')) {
        return;
    }
    const { worker } = await import('../mocks/browser');
    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start();
};
main(renderApplication);
