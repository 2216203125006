import queryString from 'query-string';
import { getFromLocalStorage, saveToLocalStorage } from './localStorage';

export const detectFeatureToggles = () => {
    const detectToggle = (transform, name) => {
        const [_, searchParams = ''] = window.location.hash.split('?');
        const params = queryString.parse(searchParams);

        const toggleValue = params[name];
        const value = toggleValue ? saveToLocalStorage(name, toggleValue) : getFromLocalStorage(name);

        return value && transform(value);
    };

    const BooleanToggle = (value) => value === 'true' || value === '1';

    return {
        enforcedEnv: detectToggle(String, 'ft_env'),
        enforcedLocale: detectToggle(String, 'ft_enforceLocale'),
        mockedToken: detectToggle(String, 'ft_mockToken'),
        useValidToken: detectToggle(BooleanToggle, 'ft_useValidToken'),
        useUpload: detectToggle(BooleanToggle, 'ft_useUpload'),
        useRefresh: detectToggle(BooleanToggle, 'ft_refresh'),
    };
};
